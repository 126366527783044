// Paths
$font-path: 			"../fonts/";
$img-path:				"../images/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
// -------------------------

/* ----------------------------------------------------------------
	Base Colors
-----------------------------------------------------------------*/

$brand-color:             #28bce0;
$brand-color-light:		    #E9F0FA;
$brand-color-dark:        #047793;

$dark-color:              #232323;

$body-color:              #555;
$topbar-color:			  #707070;
$heading-color:           $brand-color;
$grey-color: 			  #a0a0a0;

$brand-title-color:       $brand-color-dark;
$brand-title-bg:          $brand-color-light;

$primary-color: 		  $brand-color;
$primary-color-bg: 		  $brand-color-light;

$secondary-color: 		  $brand-color-dark;
$secondary-color-bg: 	  $brand-color-light;

$section-bg:			  $brand-color-light;
$contact-bg:              #f6f6f6;

$brand-primary:           $brand-color !default;
$brand-success:           #5cb85c !default;
$brand-info:              $body-color !default;
$brand-warning:           #F39C12 !default;
$brand-danger:            #d9534f !default;

$link-color:            		$brand-color;
$dropdown-link-hover-color : 	$link-color;
$dropdown-link-hover-bg: 		#f0f0f0;
$dropdown-bg: 					#e5e5e5;
$dropdown-submenu-bg:			#d2d2d2;

$menu_bg:                     #4f7dd5;
$menu_color:                  #fff;
$menu_hover_bg:               #244d9d;
$menu_hover_color:            #fff;

$footer_bg:                   #424242;
$footer_color:                #fff;
$footer_color_focus:          #efefef;
$footer_title_size:           15px;
$footer_font_size:            13px;

$submenu_bg:                  #fff;
$submenu_color:               #789cdf;
$submenu_hover_bg:            darken($submenu_bg, 10%);
$submenu_hover_color:         #244d9d;
$submenu_top_border_width:    0;
$submenu_top_border_style:    'solid';
$submenu_top_border_color:    #ffffff;
$submenu_border_width:        0px;
$submenu_border_style:        'solid';
$submenu_border_color:        '#ffffff';

$slider_tiny_height:          100px;

$twitter-color: #00aced;
$facebook-color: #3b5998;
$linkedin-color: #007bb5;
$youtube-color: #bb0000;
$googleplus-color: #dd4b39;
$pinterest-color: #cb2027;
$instagram-color: #205A80;
$pinterest-color: #cb2027;
$vimeo-color: #aad450;
$rss-color: #E28F25;


$state-success-text:             #3c763d !default;
$state-success-bg:               #dff0d8 !default;
$state-success-border:           darken(adjust-hue($state-success-bg, -10), 5%) !default;

$state-info-text:                $brand-title-color !default;
$state-info-bg:                  $brand-title-bg !default;
$state-info-border:              darken(adjust-hue($state-info-bg, -10), 5%) !default;

$state-warning-text:             #8a6d3b !default;
$state-warning-bg:               #fcf8e3 !default;
$state-warning-border:           darken(adjust-hue($state-warning-bg, -10), 5%) !default;

$state-danger-text:              #a94442 !default;
$state-danger-bg:                #f2dede !default;
$state-danger-border:            darken(adjust-hue($state-danger-bg, -10), 5%) !default;

/* ----------------------------------------------------------------
	Base variables
-----------------------------------------------------------------*/

$line-height-base:            1.2;
$font-weight-base:      	    300;
$font-tt-base:          	    none;
$font-spacing-base:     	    0;


/* ----------------------------------------------------------------
	Margins
-----------------------------------------------------------------*/

$margin-base:                   25px;
$margin-small:                  20px;
$margin-big:                    35px;


// Fonts
// -------------------------

$font-family-base:        "Helvetica Neue", Helvetica, Arial, sans-serif;
$secondary-font:          "Helvetica Neue", Helvetica, Arial, sans-serif;
$heading-font:            $secondary-font;


$font-size-base:          16px;
$font-size-md:            20px;
$font-size-large:         20px;
$font-size-small:         14px;
$font-size-h1:            44px;
$font-size-h2:            38px;
$font-size-h3:            30px;
$font-size-h4:            24px;
$font-size-h5:            20px;
$font-size-h6:            16px;
$h3-color:                #424242;
$h5-color:                #424242;


/* ----------------------------------------------------------------
	Layouts
-----------------------------------------------------------------*/

$containter-mobile-padding: 	  60px;

$postcontent:                     860px;
$postcontent-margin:              20px;

$sidebar:                       1140px - ($postcontent + $postcontent-margin);
$bothsidebar:                   1140px - ( ( $sidebar * 2 ) + ( $postcontent-margin * 2 ) );


$postcontent-md:				  690px;
$postcontent-margin-md:			  20px;

$sidebar-md:					  940px - ($postcontent-md + $postcontent-margin-md);
$bothsidebar-md:				  940px - ( ( $sidebar-md * 2 ) + ( $postcontent-margin-md * 2 ) );


$section-padding:				  30px;
$section-margin:				  10px;



/* ----------------------------------------------------------------
	Top Bar
-----------------------------------------------------------------*/

$topbar-height:                 40px;
$topbar-line-height:            $topbar-height;



/* ----------------------------------------------------------------
	Header
-----------------------------------------------------------------*/

$header-height:                 90px;
$header-menu-border:		        0;
$sticky-header-height:          40px;
$logo-height:				            70px;
$logo-sticky-height:		        60px;
$menu-after-height:			        60px;


/* ----------------------------------------------------------------
	Primary Menu
-----------------------------------------------------------------*/

$primary-menu-bg:				    #eeeeee;
$primary-menu-font:             	$secondary-font;
$primary-menu-font-weight:      	400;
$primary-menu-font-size:        	18px;
$primary-menu-font-tt:          	none;
$primary-menu-font-color:       	#707070;
$primary-menu-font-spacing:     	.1pt;

/* ----------------------------------------------------------------
	Page Title
-----------------------------------------------------------------*/


/*
$page-title-padding:					50px;
$page-title-parallax-padding:			100px;
$page-title-mini-padding:				20px;

$page-title-bg:							#F5F5F5;
$page-title-bg-dark:					#333;

$page-title-size:						28px;
$page-title-subtitle-size:				18px;

$page-title-parallax-size:				40px;
$page-title-parallax-subtitle-size:		22px;

$page-title-mini-size:					18px;
*/


/* ----------------------------------------------------------------
Components
-----------------------------------------------------------------*/

$padding-base-vertical:     6px !default;
$padding-base-horizontal:   12px !default;

$padding-md-vertical:     15px !default;
$padding-md-horizontal:   20px !default;

$padding-large-vertical:    20px !default;
$padding-large-horizontal:  22px !default;

$padding-small-vertical:    5px !default;
$padding-small-horizontal:  10px !default;

$padding-xs-vertical:       1px !default;
$padding-xs-horizontal:     5px !default;

$line-height-large:         1.3333333 !default; // extra decimals for Win 8.1 Chrome
$line-height-small:         1.5 !default;

$border-radius-base:        2px !default;
$border-radius-large:       4px !default;
$border-radius-small:       1px !default;


// Buttons
// -------------------------

$btn-default-color:              #fff;
$btn-default-bg:                 $dark-color;
$btn-default-border:             $dark-color;

$btn-primary-color:              #fff;
$btn-primary-bg:                 $brand-color;
$btn-primary-border:             $brand-color;

$btn-secondary-color:            $brand-color;
$btn-secondary-bg:               transparent;
$btn-secondary-border:           $brand-color;

$btn-success-color:              #fff;
$btn-success-bg:                 $brand-success;
$btn-success-border:             $brand-success;

$btn-info-color:                 $brand-info;
$btn-info-bg:                    #fff;
$btn-info-border:                #fff;


$btn-warning-color:              #fff;
$btn-warning-bg:                 $brand-warning;
$btn-warning-border:             $brand-warning;


$btn-danger-color:               #fff;
$btn-danger-bg:                  $brand-danger;
$btn-danger-border:              $brand-danger;


$ipunt-color:                    $body-color;
$input-bg:                       #fff;
$input-bg-disabled:              #a0a0a0;
$input-border:                   #ccc;
$input-border-focus:             $brand-color;
$input-border-radius:            3px;


$section_bg:                     #efefef;
$contact_bg:                     #f6f6f6;
$section_title_color:            #000000;
$section_text_color:             #424242;
$related_box_bg:                 #fff;
$related_box_title_color:        #517dd5;
$related_box_text_color:         #424242;
$icon_box_bg:                    'transparent';
$icon_box_color:                 #ffffff;
$icon_box_size:                  48px;


/* Teseo Icons */

$icons-font-family: "teseoicons";

$icon-nurse: "\e900";
$icon-ear: "\e901";
$icon-comment: "\e902";
$icon-search: "\e903";
$icon-phone: "\e904";
$icon-phone-outline: "\e905";
$icon-home: "\e906";
$icon-chatbubble: "\e907";
$icon-chatbubbles: "\e908";
$icon-chatbox: "\e909";
$icon-chatboxes: "\e90a";
$icon-alert: "\e90b";
$icon-help: "\e90c";
$icon-info: "\e90d";
$icon-warning: "\e90e";
$icon-at: "\e90f";
$icon-pound: "\e910";
$icon-asterisk: "\e911";
$icon-attach: "\e912";
$icon-walk: "\e913";
$icon-bicycle: "\e914";
$icon-car: "\e915";
$icon-bus: "\e916";
$icon-subway: "\e917";
$icon-train: "\e918";
$icon-boat: "\e919";
$icon-plane: "\e91a";
$icon-cart: "\e91b";
$icon-bar: "\e91c";
$icon-restaurant: "\e91d";
$icon-lab: "\e91e";
$icon-bag: "\e91f";
$icon-card: "\e920";
$icon-android-home: "\e921";
$icon-tshirt: "\e922";
$icon-trophy: "\e923";
$icon-university: "\e924";
$icon-umbrella: "\e925";
$icon-cloud: "\e926";
$icon-eye: "\e927";
$icon-flag: "\e928";
$icon-flame: "\e929";
$icon-leaf: "\e92a";
$icon-waterdrop: "\e92b";
$icon-lightbulb: "\e92c";
$icon-notifications: "\e92d";
$icon-location: "\e92e";
$icon-map: "\e92f";
$icon-print: "\e930";
$icon-mail: "\e931";
$icon-person: "\e932";
$icon-people: "\e933";
$icon-time: "\e934";
$icon-clock: "\e935";
$icon-refresh: "\e936";
$icon-sync: "\e937";
$icon-loop-strong: "\e938";
$icon-loop: "\e939";
$icon-reload: "\e93a";
$icon-more-thin: "\e93b";
$icon-more: "\e93c";
$icon-navicon: "\e93d";
$icon-navicon-round: "\e93e";
$icon-arrow-down-thin: "\e93f";
$icon-forward: "\e940";
$icon-reply: "\e941";
$icon-reply-all: "\e942";
$icon-arrow-left-thin: "\e943";
$icon-arrow-right-thin: "\e944";
$icon-arrow-up-thin: "\e945";
$icon-chevron-down: "\e946";
$icon-chevron-left: "\e947";
$icon-chevron-right: "\e948";
$icon-chevron-up: "\e949";
$icon-checkmark: "\e94b";
$icon-close: "\e94a";
$icon-minus: "\e94c";
$icon-plus: "\e94d";
$icon-edit: "\e94e";
$icon-close-thin: "\e988";
$icon-plus-thin: "\e989";
$icon-minus-thin: "\e98a";
$icon-thumbsdown: "\e94f";
$icon-thumbsup: "\e950";
$icon-delete: "\e951";
$icon-unlock: "\e952";
$icon-lock: "\e953";
$icon-pin: "\e954";
$icon-paper-airplane: "\e955";
$icon-pricetag: "\e956";
$icon-pricetags: "\e957";
$icon-stats-bars: "\e958";
$icon-pie-graph: "\e959";
$icon-wifi: "\e95a";
$icon-play: "\e95b";
$icon-pause: "\e95c";
$icon-stop: "\e95d";
$icon-record: "\e95e";
$icon-star: "\e95f";
$icon-heart: "\e960";
$icon-gear: "\e961";
$icon-music-note: "\e962";
$icon-color-palette: "\e963";
$icon-image: "\e964";
$icon-images: "\e965";
$icon-film: "\e966";
$icon-calendar: "\e967";
$icon-calculator: "\e968";
$icon-bookmark-strong: "\e969";
$icon-bookmark: "\e96a";
$icon-camera: "\e96b";
$icon-list: "\e96c";
$icon-clipboard: "\e96d";
$icon-document: "\e96e";
$icon-folder: "\e96f";
$icon-monitor: "\e970";
$icon-laptop: "\e971";
$icon-ipad: "\e972";
$icon-iphone: "\e973";
$icon-earth: "\e974";
$icon-briefcase: "\e975";
$icon-medkit: "\e976";
$icon-share: "\e977";
$icon-load-a: "\e978";
$icon-load-b: "\e979";
$icon-load-c: "\e97a";
$icon-load-d: "\e97b";
$icon-social-facebook: "\e97c";
$icon-social-google: "\e97d";
$icon-social-googleplus: "\e97e";
$icon-social-instagram: "\e97f";
$icon-social-linkedin: "\e980";
$icon-social-linkedin-fill: "\e981";
$icon-social-pinterest: "\e982";
$icon-social-rss: "\e983";
$icon-social-skype: "\e984";
$icon-social-twitter: "\e985";
$icon-social-vimeo: "\e986";
$icon-social-youtube: "\e987";
